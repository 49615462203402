<template>
  <div class="page">
    <div class="main">
      <h1 class="title">{{ info.title }}</h1>
      <div class="source">
        来源 : {{ info.source }} 发布时间 : {{ info.create_time?info.create_time.split(" ")[0]:'' }}
      </div>

      <div class="content"  style='white-space: pre-wrap;' v-html="info.maincontent" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Detail",
  data() {
    return {
      info: {},
    };
  },

  watch: {
    $route() {},
  },
  mounted() {
    this.$fecth
      .get("news/getDataById", { params: { id: this.$route.query.id } })
      .then((res) => {
        this.info = res;
      });
  },
  
};
</script>

<style lang="less" scoped>
.main {
  position: relative;
  background-color: #ffffff;
  padding: 40px 60px;
  border-radius: 6px;
  .title {
    text-align: center;
    margin-bottom: 30px;
  }
  .source {
    position: relative;
    text-align: center;
    color: gray;
  }
}

.content {
  line-height: 28px;
  font-size: 16px !important;
  letter-spacing: 3px;
  margin-top: 40px;
}
</style>
